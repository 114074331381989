import logo from './logo.svg';
import './App.css';

function App() {
  // Dosya yükleme işlemini gerçekleştirecek işlev
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Yüklenen dosyayı alın
    console.log('Selected file:', file);

    // Resmi base64'e çevirme işlemi
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result; // Resmi base64 olarak alın
      console.log('Base64 image data:', imageData);

      // Sunucuya dosyayı göndermek için bir HTTP isteği yapın (fetch veya axios kullanarak)
      fetch('https://backend.wisitcard.com:8085/product/uploadImage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageData }), // Resmi base64 verisini JSON olarak gönderin
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Sunucudan HTML yanıtı alırsanız, burada uygun bir işlem yapabilirsiniz.
        return response.json(); // Eğer JSON yanıtı almak istiyorsanız
      })
      .then(data => {
        console.log('Response from server:', data);
        // Sunucudan gelen yanıta göre gerekli işlemleri gerçekleştirin
      })
      .catch(error => {
        console.error('Error while uploading file:', error);
      });
    };
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        {/* Dosya yükleme butonu */}
        <input type="file" onChange={handleFileUpload} />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;